// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
/// @author Muneeba Amjad

.nav-wrap{
    flex-grow: 1;
    position: relative;
    width: 100%;
    z-index:80;
    .container, container{
        width: 100%;
        max-width: 100%;
    }

        .searchbox-wrap{
            display: flex;
            align-items: center;
            padding-right: 48px;
        }
        // @include respond-below(sm) {
        //     .searchbox-wrap{
        //         flex-direction: row-reverse;
    
        //     }
        //   }

    .app-logo{
        height: 50px;
        width: 100px;
        vertical-align: middle;
        display: flex;
        position: relative;
        @media (max-width: 767px) {
            width: 160px;
        }
        svg {
            position: absolute;
            top: 0;
            left: 0;
            // width: 100%;
            height: 100%;
            padding: 0;
        }
        img{
            width: 100%;
            height: 100%;
            padding: 10px 20px;
        }
        @include respond-below(sm) {
           margin-top: 8px;
           margin-bottom: 8px;
          }
    }
  
    .nav_righticons{
        margin-right: 10px;
        display: flex;
        button{
            padding: 12px 17.5px;
            &:hover{
                background-color: transparent;
            }
        }
    }
.sec-left{
    padding-right: 10px !important;
}
.top-right-profile{
        padding-bottom: 5px;
        position: relative;
        padding-left: 12px;
        &:before{
            content: '';
            width: 1px;
            height: 20px;
            background: #C4C4C4;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    button{
        padding-right: 0;
        &:hover{
            background-color: transparent;
        }
    }
    }

    .sec-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .sec-right{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    @include respond-between(sm, md) {
        .sec-left {
            margin-top: 5px;
        }
      }
      @include respond-below(md) {
        .sec-left {
            width: 100%;
        }
        .sec-right {
            justify-content: flex-end;
            width: 100%;
        }
      }
    
}

.MuiCardHeader-root {
    padding: 0px !important;
}

.MuiIconButton-root {
    padding: 7px ;
}

.react-multi-carousel-item{
    width: 280px !important;
}

#demoPopper{
    @include respond-below(sm) {
        top:4px !important;
        // left: 1px !important;
        transform: translate3d(0, 64px, 0px) !important;
      }

}