// -----------------------------------------------------------------------------
// This file contains all styles related to the Signup of the site/application.
// -----------------------------------------------------------------------------
/// @author Muneeba Amjad
// Signup styles
.signup-image {
  // background-image: url(https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1615843861signup-svg1615843861.svg);
  // background: linear-gradient(180deg, #007EC6 0%, #0057BE 100%),url(https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1615843861signup-svg1615843861.svg);
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center center;
  //background:
  //linear-gradient(180deg, rgba(0,126,198,0.7990546560421043) 26%, rgba(0,87,190,0.8018557764902836) 79%),
  //url(https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1638972291loginimg-svg1638972291.svg);
  background:url(https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1638972291loginimg-svg1638972291.svg);
  background-position: 50% 50%;
 height: 100vh;
}