// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// Author: Muneeba Amjad 
// -----------------------------------------------------------------------------
// .dashboard-wrap{
//     margin-top: 2%;
// }
.none{
    display: none !important;
}
  
$icon-color: RGBA(255,255,255,0.6);
.icon-color:before{
    color: $icon-color;
  }
.icon-gray::before{
  color: $icon-grey;
}
.icon-gray{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.room_end:after{
  content: "";
  background: #C4C4C4;
  position: absolute;
  right: -6px;
  height: 45%;
  width: 1.5px;
  top: 4%;
}
.top-right_btn_vmm{
  position: absolute;
  right: 2%;
  top: 4%;
}
// .vm_panel-ques {
//   margin-bottom: 100px;
// }
.vm_panel-ques .vm-radiobtn{
  margin: auto 0 10px;
  width: 100%;
}
.vm_panel-ques .vm_multi-group{
  display: flex;
  //flex-direction: column;
}
.ques_title {
  color: #1F2933;
}
.vm_panel-ques .vm_form_wrap {
  width: 100%;
}
.profile_card_ques_wrap {
  display: flex;
  justify-content: flex-end;
}
.profile_card_ques div div span {
  margin-left: -2.3em !important;
}