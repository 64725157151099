// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
@media screen and (max-width: 380px)  {
  .toast {
    width: 300px !important;
  }
  .notification-message {
    width: 170px;
  }
  .top-right {
    right: 5px !important;
  }
}
