.notification-container {
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    z-index: 999999
}

.top-right {
    top: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}


.notification {
    transition: .3s ease;
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 30px;
    margin-bottom: 15px;
    width: 300px;
    max-height: 100px;
    border-radius: 16px;
    color: #ffffff;
    opacity: .9;
    background-position: 15px;
    background-repeat: no-repeat;
}

.notification:hover {
    box-shadow: 0 0 12px #fff;
    opacity: 1;
    cursor: pointer
}

.notification-message {
    margin: 0;
    text-align: left;
    height: 18px;
    margin-left: -1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notification-points {
    float: right;
    margin: 0;
    text-align: right;
    height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(253, 191, 51, 1);
    margin-left: 16px;
    font-weight: bold;
}

.notification-image {
    float: left;
    margin-right: 15px;
}

.notification-image img {
    width: 30px;
    height: 30px;
}

.toast {
    height: auto;
    width: 375px;
    color: #fff;
    padding: 16px;
    background: rgba(34, 34, 34, 1);
    display: flex;
    align-items: center;
}

/*.notification-container button {*/
/*    position: relative;*/
/*    right: -.3em;*/
/*    top: -.3em;*/
/*    float: right;*/
/*    font-weight: 700;*/
/*    color: #fff;*/
/*    outline: none;*/
/*    border: none;*/
/*    text-shadow: 0 1px 0 #fff;*/
/*    opacity: .8;*/
/*    line-height: 1;*/
/*    font-size: 16px;*/
/*    padding: 0;*/
/*    cursor: pointer;*/
/*    background: 0 0;*/
/*    border: 0*/
/*}*/

@keyframes toast-in-right {
    from {
        transform: translateX(100%);

    }
    to {
        transform: translateX(0);
    }
}

