// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Muneeba Amjad
/// @param {Bool} $self [false] - Whether or not to include current selector

@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly

/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––
/// @author Muneeba Amjad
// A map of breakpoints. These are handeling using same break points of Material UI refered in themeing js files.

$breakpoints: (
  xs: 480,
  sm: 768px,
  md: 1024px,
  lg: 1280px,
  xl: 1920px,
);


//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  
  // If one or both of the breakpoints don't exist.
  } @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {

      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

// Sass Usage for beginner
/*
@include respond-above(sm) {
  .element {
    font-weight: bold;
  }
}
Here’s the CSS output:

@media (min-width: 600px) {
  .element {
    font-weight: bold;
  }
}
__________________________
__________________________

@include respond-below(sm) {
  .element {
    font-weight: bold;
  }
}

Here’s the CSS output:

@media (max-width: 600px) {
  .element {
    font-weight: bold;
  }
}

__________________________
__________________________

@include respond-between(sm, md) {
  .element {
    font-weight: bold;
  }
}

Here’s the CSS output:

@media (min-width: 600px) and (max-width: 960px) {
  .element {
    font-weight: bold;
  }
}

*/
