// -----------------------------------------------------------------------------
// This file contains all styles related to the Loader component.
// Author: Muneeba Amjad 
// -----------------------------------------------------------------------------
.loader_wrap{
  
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    .loader img{
        width:50px;
        height:auto;
        /* animation:rotation 2s infinite linear; */
        /* animation:pulse 0.7s infinite linear; */
    }
}


@keyframes rotation{
	0%{
		transform:rotate(0deg);
	}
	100%{
		transform:rotate(360deg);
	}
}

@keyframes pulse {
    0% {
        transform:scale(0.6);

    }
    30% {
        transform:scale(0.7);

    }
    60% {
        transform:scale(0.8);

    }
    70% {
        transform:scale(0.7);

    }
    100% {
        transform:scale(0.6);

    }
  }