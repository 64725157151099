// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// Author: Muneeba Amjad 
// -----------------------------------------------------------------------------
.btn-primary {
  background: #0057BE;
  color: #fff;
}

.attendeBadge {
  border: 1px solid #EB5757;
  color: #EB5757 !important;
}

.speakerBadge {
  border: 1px solid #1D9D48;
  color: #1D9D48 !important;
}

.exhibitorBadge {
  border: 1px solid #0057BE;
  color: #0057BE !important;
}

.recruiterBadge {
  border: 1px solid #9742EC;
  color: #9742EC !important;
}

.color_attende {
  background: #EB5757 !important;
}

.color_speaker {
  background: #1D9D48 !important;
}

.color_exhibitor {
  background: #0057BE !important;
}

.color_recruiter {
  background: #9742EC !important;
}

.redHeart {
  color: #EA4949 !important;
}

.slick-prev:before, .slick-next:before {
  opacity: 1 !important;
}

.slick-arrow.slick-next {
  right: -10px;
}

.slick-arrow.slick-prev {
  left: -10px;
}

.slick-slider.slick-initialized > svg {
  display: flex;
  background: white;
  border-radius: 25px;
  text-align: center;
  top: 20%;
  color: #0061F9;
  width: 20px;
  height: 20px;
  font-size: 12px;
  padding: 5px;
  justify-content: center;
}

.vm-stepper {
  min-width: 500px;
}

@media screen and (max-width: 550px) {
  .vm-stepper {
    min-width: auto;
  }
}

.vm-radiobtn {
  background: #fff;
  border: 1px solid #dddddd;
  padding-right: 10px;
  border-radius: 6px;
  color: #4D5966;
}

.is_selected {
  border: 1px solid #F2662E;
  background: rgba(242, 102, 46, 0.1);
  padding-right: 10px;
}

.is_selected > span {
  color: #F2662E !important;
}

.gutter-bottom {
  margin-bottom: 1.5rem;
}

.swal2-styled.swal2-confirm.swal-vm-button {
  font-size: 12px !important;
  padding: 8px 10px;
  color: #ffffff;
  background: linear-gradient(90deg, rgba(239, 67, 115, 1) 0%, rgba(242, 101, 48, 1) 100%) !important;
  min-width: 100px;
}
.ques_slider .slick-prev:before, .ques_slider .slick-next:before {
  color: #1F2933;
}

.ques_slider .slick-dots li button:before {
  font-size: 12px;
  line-height: 26px;
}
.ques_slider .slick-dots li.slick-active button:before {
  color: #F2662E;
  opacity: 1;
}

.ques_slider .slick-dots li button:before {
  color: #F2662E;
}
.ques_slider {
background: #ffffff;
}