html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.App-header {
    background-color: #222;
    height: 40px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.App-header h1 {
    font-size: 16px;
    font-weight: 200;
}

.App-logo {
    height: 60%;
    width: auto;
}

.App-main {
    position: relative;
    /* width: 49.5vw;*/
    /* height: calc(40vw * 0.6); */
    width: 100%;
    height: 480px;
    margin: auto;
    /* border: 1px solid lightblue; */
    border: 0;
}

.App-control-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.share-contact {
    display: flex;
    align-items: center;
    justify-content: left;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 80px;
    width: 135px;
}

.vm-contact_name {
    display: flex;
    align-items: center;
    justify-content: left;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 160px;
    height: 80px;
    z-index: 60;
}


.vm-contact_name span {
    color: #ffffff;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 20px;
}

.vm-contact_name span a:hover {
    color: #FFFFFF;
}


@media screen and (max-width:580px) {
    .App-control-container {
        bottom: 30px;
    }

    .share-contact {
        justify-content: left;
        height: 10px;
        width: 135px;
        right: 0;
        margin: auto;
        bottom: 20px;
    }

    .vm-contact_name {
        justify-content: left;
        height: 10px;
        width: 130px;
        left: 0;
        margin: auto;
        bottom: 20px;
    }
}

.OT_video-poster {
    position: unset !important;
}

/* .App-control-container.hidden {
    display: none;
} */

.App-control-container .ots-video-control {
    width: 50px;
    height: 50px;
    margin: 20px 5px !important;
    border: 2px solid white;
    border-radius: 50%;
    background-position: center;
    /* background-color: rgba(27, 134, 144, 0.4); */
    /* background-color: lightgrey; */
    background-repeat: no-repeat;
    cursor: pointer;
}

.App-control-container .ots-video-control.audio {
    background-image: url(images/mic.svg);
    background-size: inherit;
    /* background-color:#6a6a6a ; */
}

/*.App-control-container .ots-video-control.audio:hover,*/
.App-control-container .ots-video-control.audio.muted {
    background-image: url(images/mic-off.svg);
    background-size: inherit;
    background-color: #e6052e;
    border: 2px solid #e6052e;
}

.App-control-container .ots-video-control.video {
    background-image: url(images/video.svg);
    background-size: inherit;
    /* background-color:#6a6a6a ; */
}

/*.App-control-container .ots-video-control.video:hover,*/
.App-control-container .ots-video-control.video.muted {
    background-image: url(images/video-off.svg);
    background-size: inherit;
    background-color: #e6052e;
    border: 2px solid #e6052e;
}

.App-control-container .ots-video-control.end-call {
    background-image: url(images/phonecall.svg);
    background-size: unset;
    background-color: #e6052e;
    width: 70px;
    border-radius: 25px;
    border: 0;
}

.App-control-container .ots-video-control.settings {
    background-image: url(images/settings.svg);
    background-size: inherit;

}

.App-control-container .ots-video-control.sharescreen {
    background-image: url(images/sharescreen.svg);
    background-size: inherit;

}

.App-control-container .ots-video-control.sharescreen.shared {
    /* background-image: url(images/mic-off.svg);
    background-size: inherit; */
    background-color: #8AB4F8;
    border: 2px solid #8AB4F8;
}

.react-responsive-modal-modal {
    max-width: 900px;
    width: 900px;
    min-height: 700px;
    background: transparent;
    box-shadow: none !important;
}

.App-video-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.App-mask {
    width: 100%;
    height: 100%;
    position: relative;
    color: white;
    background: rgba(27, 134, 144, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.App-mask .react-spinner {
    position: absolute;
}

.App-mask .message {
    font-weight: 200;
}

.App-mask .message.with-spinner {
    position: absolute;
    top: 55%;
}

.App-mask .message.button {
    border: 1px solid white;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: white;
    color: black;
    font-size: 14px !important;
    font-family: sans-serif;
    font-size: medium;
}

.App-mask .message.button:focus:active,
.message.button:focus,
.message.button:active {
    background-image: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.App-video-container .video-container {
    width: 100%;
    height: 100%;
    display: flex;
}

.App-video-container .video-container.small {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 160px;
    height: 96px;
    border: 1px solid #fcba00;
    z-index: 2;
}

.vmm-controls_wrap {
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    background: rgb(0 0 0 / 55%);
    height: 80px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.vmm-controls_wrap:hover {
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
    background: rgb(0 0 0 / 55%);
    height: 80px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.App-video-container .video-container.small.left {
    left: 20px;
    border: 1px solid #00fcc2;
}

.App-video-container .video-container.small.down {
    top: 130px;
    border: 1px solid #00FCC2;
}

.App-video-container .video-container.hidden {
    display: none;
}

.App-video-container .video-container.active-gt2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
}

.App-video-container .video-container.active-gt2.hidden {
    display: none;
}

.App-video-container .video-container.active-gt2 .OT_subscriber {
    margin: 0;
    padding: 0;
    display: inline-block;
    font-size: 0;
    flex-basis: 50%;
    box-sizing: border-box;
    width: auto !important;
    height: auto !important;
}

.App-video-container .video-container.active-gt2.active-odd .OT_subscriber:first-child {
    flex-basis: 100%;
}

.vm-contact_name span b a {
    color: #ffffff !important;
}

.videoMessage {
    background: white;
    position: absolute;
    bottom: -10em;
    font-size: 16px;
    font-weight: bold;
}

/* Media query for iPhone */
@media only screen and (max-width: 375px) {
    .videoMessage {
        bottom: -15em;
    }
}

/* Media query for Samsung mobile devices */
@media only screen and (max-width: 480px) {
    .videoMessage {
        bottom: -15em;
    }
}
