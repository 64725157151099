// -----------------------------------------------------------------------------
// This file contains all styles related to the form of the site/application.
// -----------------------------------------------------------------------------

// Event Login Sign in box
.login_footer-links{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
}
.login-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  position: relative;
height: 80%;

  h1{
      margin-top: 0;
  }

}
.form-wrap{
    width: 100%;
  label{
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    color: rgba(92, 95, 100, 1);
  }
  h4{
      margin-bottom: 0;
  }  
  .login-btn{
      margin: 2rem 0;
      padding: 1.3rem 0;
      font-size: 1.3rem;
  }
  input[type="text"]
  {
    font-size:14px !important;
  }
  input[type="password"]
  {
    font-size:14px !important;
  }
}
