// -----------------------------------------------------------------------------
// This file contains all styles related to the Loader component.
// Author: Muneeba Amjad 
// -----------------------------------------------------------------------------

.timer-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  
    .timer{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px 12px;
    }
    .number{
        font-size: 22px;
    }
}