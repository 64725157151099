@font-face {
  font-family: 'icomoon';
  src:  url('https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1638442994icomoon-eot1638442994.eot?ny1kai');
  src:  url('https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1638442994icomoon-eot1638442994.eot?ny1kai#iefix') format('embedded-opentype'),
  url('https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1638442994icomoon-ttf1638442994.ttf') format('truetype'),
  url('https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1638442994icomoon-woff1638442994.woff') format('woff'),
  url('https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1638442994icomoon-svg1638442994.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-downloadwebinar:before {
  content: "\e903";
  color: #0061f9;
}
.icon-grid:before {
  content: "\e901";
  color: #0061F9
}
.icon-handmove:before {
  content: "\e902";
  color: #5C5F64;
}
.icon-extendcall:before {
  content: "\e900";
  color: #fff;
}