// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
@font-face {
    font-family: 'AvenirLTPro-Book';
    src: url(../fonts/AvenirLTPro-Book.eot);
    src: url(../fonts/AvenirLTPro-Book.eot?#iefix) format('embedded-opentype'),
         url(../fonts/AvenirLTPro-Book.otf) format('otf'),
         url(../fonts/AvenirLTPro-Book.svg) format('svg'),
         url(../fonts/AvenirLTPro-Book.ttf) format('truetype'),
         url(../fonts/AvenirLTPro-Book.woff) format('woff'),
         url(../fonts/AvenirLTPro-Book.woff2) format('woff2');
  }

@font-face {
  font-family: 'AvenirLTPro-Black';
  src: url(../fonts/AvenirLTPro-Black.eot);
  src: url(../fonts/AvenirLTPro-Black.eot?#iefix) format('embedded-opentype'),
  url(../fonts/AvenirLTPro-Black.ttf) format('truetype'),
  url(../fonts/AvenirLTPro-Black.woff) format('woff'),
  url(../fonts/AvenirLTPro-Black.woff2) format('woff2');
}

@font-face {
  font-family: 'AvenirLTPro-Medium';
  src: url(../fonts/AvenirLTPro-Medium.eot);
  src: url(../fonts/AvenirLTPro-Medium.eot?#iefix) format('embedded-opentype'),
  url(../fonts/AvenirLTPro-Medium.ttf) format('truetype'),
  url(../fonts/AvenirLTPro-Medium.woff) format('woff'),
  url(../fonts/AvenirLTPro-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Digital Numbers';
  font-style: normal;
  font-weight: normal;
  src: local('Digital Numbers'), url('../fonts/DigitalNumbers-Regular.woff') format('woff');
}

/// Regular font family
/// @type List
$text-font-stack: 'AvenirLTPro-Book', 'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;





/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// card grey
/// @type Color
$icon-grey: #474748;



/// Container's maximum width
/// @type Length
$max-width: 1180px !default;





/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;
// Button
$Blue: #0057BE;